import axios from "axios";

const bbb = getURL()+"/system/anonymousAccess/system/ApiCateLieController"
// const bbb = "/api/druid"
export function getURL(){
    return "http://ydatapower.com/api";
}
export function getURLImg(){
    return "http://api.ydatapower.com";
}
//将首页数据展示封装成一个方法
export function getAPIList(pId){
    const aaa = {
        deptId: null,
        pid: null,
        name: null,
        sort: null,
        icon: null,
        status: null,
        createdAt: null,
        description: null,
        imageUrl: null,
        pageType: null,
        bannerUrl: null,
        contentIntro: null,
        linkAddress: null,
        openWith: null,
        createdBy: null,
        createdTime: null,
        modifiedBy: null,
        modifiedTime: null
    }
    aaa.pid = pId
       return  getAxios('/categoryList',aaa).then(data =>{
       return data
    })
}

export function getAxios(url,data) {
    if (data){
        return axios.get(bbb+url,{params:data}).then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }
    return axios.get(bbb+url)

        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function postAxios(url,data) {
    if (data){
        return axios.post(bbb+url,data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }
    return axios.post(bbb+url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function putAxios(url,data) {
    if (data){
        return axios.put(bbb+url,data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }
    return axios.put(bbb+url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

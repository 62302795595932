// eventBus.js

import Vue from 'vue';
import Cookies from 'js-cookie'
import router from '@/router/router';
const eventBus = new Vue();

export default eventBus;


//如果有网址，跳转网址
export function routerA(url){
    if (url != null ){
        window.open(url, "");
    }
}

export function getImg404(){
    return require('@/assets/404.png')
}

//往详情页发送信息
export function getLogin(data){
        eventBus.$emit('eventName', true);
}

// 登录失效自动退出
export function remLogin(){
    eventBus.$emit('remLogin123');
}

export function getListW(){
        eventBus.$emit('getListWWW');
}

export function getListClassName(data){

    eventBus.$emit('getListClassNameH',data);
}
export function getListClassName2(data){
    eventBus.$emit('getListClassNameH2',data);
}



//文件下载
export function downloadFile(URL) {

    const fileUrl = "/api"+URL;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

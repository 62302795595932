<template>
  <div>
    <div style="width: calc(100% +10px);height: 100%;  ">
      <div class="divTop">
        <img class="imgDiv" style="z-index: 6666" :src="getImg()+'/' + logo.replace(/^\/+/, '')" alt="" @error="handleImageError">

      <img style="filter:;background-color: #9a9a9a;width:100%;height:60px" :src="getImg()+'/' + logoAreaImg.replace(/^\/+/, '')"  alt="" @error="handleImageError">
      <span style="width: 2px;height: 20px;position: absolute;top: 20px;left: 305px;background-color: #FFFFFF"></span>
      <div style="position: absolute;color:#FFFFFF; left:275px; top: 6px; font-size: 14px" >
        <b>
          <ul >
            <li style="margin-left: 15px" class="liSpan" v-for="row in homeList" @click="abcURL(row)">
              <template v-if="row.openWith !== 1">
                <router-link style="font-weight: 500;color:#000" :to={path:row.to,query:{id:row.id}} >{{row.name}}</router-link>
              </template>
              <template>
                <span  v-if="row.openWith === 1" class="a">{{row.name}}</span>
              </template>
            </li>
              </ul>
        </b>
      </div>
      <div class="inputDiv">
      <!-- <el-input v-model="input" style="width: 205px;height:20px;font-size: 11px" @change="soSuo" placeholder="请输入API名称进行查询"></el-input> -->
      <!-- <i class="el-icon-search" @click="soSuo" style="position: relative;right: 25px;top: 2px;color: #b4b4b4"></i> -->
      <!-- <b>  <span>
          <span  v-if="logDenLu" ><router-link style="color:#000" to="/username">个人中心</router-link><span style="color:#000">｜</span><a style="color:#000" @click="tuiChu" class="a">退出登录</a></span>
      </span></b> -->
      <div style="height: 60px;margin-top:-11px;display:flex;align-items:center">
      <i class="el-icon-phone-outline"  style="position: relative;right: 5px;top: 0px;color:#0138f8;font-size:25px"></i>

    
        <span style="color:#000">189 1112 4568</span>

      </div>
      
      </div>
    </div>
    <div id="00" v-if="!iframeDesk" :style="winHeight"  style="overflow-y:auto;width: 100%;background-color: #FFFFFf" >
      <router-view style="height: 100%;"></router-view>
     <Loginz @custom-event="parentMethod" @custom-event2="parentMethod2" v-if="dengLu"></Loginz>
</div>
      <div id="00" v-if="iframeDesk" :style="winHeight"  style="overflow-y:auto;width: 100%;position: relative;text-align: center" >
        <iframe  style="display: inline-block" :src="iframe" width="99%" height="99%"></iframe>
        <Loginz @custom-event="parentMethod" @custom-event2="parentMethod2" v-if="dengLu"></Loginz>
      </div>

<!--      <div style="width: 100%;text-align: center;color: #9a9a9a">@WSNHJHIWPWRIO23HOIU3485304有限公司</div>-->
    </div>
  </div>
</template>

<script>
import Loginz from "@/components/set/login/loginz.vue"
import Cookies from 'js-cookie'
import eventBus, { getImg404, getListClassName, getListClassName2, getListW, remLogin} from '@/API/eventBus';
import {getAPIList, getAxios,getURLImg} from '@/API/getAxios';
import axios from "axios";
import {routerA} from "@/API/eventBus";
import {getwh} from "@/API/tools"
export default {

  name: "App",
  components: {Loginz},
  data(){
    return{
      iframe:null, // 嵌套链接
      iframeDesk:false, //显示隐藏
      winHeight:null,   //用户浏览器的高度
      logDenLu:false,//登录注册按钮显示隐藏
      dengLu:false,//登录页面是否展示
      visible: false,
      homeList:null,
      logo:'',
      logoAreaImg:'',
      show:true,
      ZhuYei:true,
      Detail:false,
      input: '',//API搜索框
      windCondition:null,//风力情况
      catalogueAPI:null,//接口目录
      commonAPI:null,//常用API
      divId01:{},
      inventoryList:null,
        // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        wareId: null,
        orderId: null,
        status: null
      },
      nameList:[]
    }
  },
  mounted() {
    
  //
  // this.go()

//     //禁用右键（防止右键查看源代码）
//     window.oncontextmenu=function(){return false;}
// // 监听键盘按下事件
//     window.addEventListener('keydown', function(event) {
//       // 屏蔽 F12 键和 Shift+Ctrl+I
//       if ((event.keyCode === 123) || (event.shiftKey && event.ctrlKey && event.keyCode === 73)) {
//         event.preventDefault(); // 阻止默认行为
//         return false;
//       }
//     });
//     //如果用户在工具栏调起开发者工具，那么判断浏览器的可视高度和可视宽度是否有改变，如有改变则关闭本页面
//     var h = window.innerHeight,w=window.innerWidth;
//     window.onresize = function () {
//       if (h!= window.innerHeight||w!=window.innerWidth){
//         window.close();
//         window.location = "about:blank";
//       }
//     }

                  

    const screenHeight = getwh().geth();
    let a = screenHeight-80
    this.winHeight = {height:a+"px"}

    getAxios('/Websitelist').then(data =>{
    if (data.rows.length != 0){
      this.logoAreaImg = data.rows[0].logoAreaImg
      this.logo = data.rows[0].logo
    }
    })

    eventBus.$on('eventName', (newValue) => {
      // 执行相应的操作，修改组件的值
      this.dengLu = true
    });
    eventBus.$on('remLogin123',() =>{
      this.tuiChu()
    });


    const authorization = localStorage.getItem('Authorization');
    if(authorization == null){
    Cookies.remove("userLogin")
    }
    if (Cookies.get("userLogin")){
      Cookies.set("Login",true,{expires: 7 })
      this.logDenLu = true
    }else {
      Cookies.set("Login",false,{expires: 7 })
      this.logDenLu = false
    }
    this.getHomeList()
    


  },

  methods:{
    getImg(){
     return getURLImg()
     
    },
       getImageUrl(imagePath) {
      // 这里可以根据你的实际需要对图片路径进行处理，确保它是正确的请求路径
         return "/img" + imagePath;
    },
    handleImageError(e){
      e.target.src = getImg404()
    },
    go(){
      if (this.logDenLu === true){
        setTimeout(() =>{
          // 发起请求
          let  uuid = localStorage.getItem('uuid')
          axios.get('/api/system/user/getShopUserInfo',{uid:uuid}).then(data =>{
            if (data.data.code === 401){
              remLogin()
              this.go()
            }
          })
        },10000)
      }

    },

    abcURL(row){

      if (row.openWith == 1){
        // 如果打开新页
        routerA(row.linkAddress)
      }
      else {
        // 如果在页面嵌套
        if (row.linkAddress !== null && row.linkAddress !== ''){
          this.iframe = row.linkAddress
          this.iframeDesk = true
        }else {

          this.iframeDesk = false
          if (this.$route.query.id === row.id){
            getListClassName2(row.id)
          }
        }
      }





    },
    soSuo(){
      if (this.$route.path !== '/API') {
        this.$router.push({path:"/API",query:{input:this.input}})
      }else {
        this.$router.push({query:{input:this.input}})
        getListW()
      }
    },
    tuiChu(){
      Cookies.remove("userLogin")
      Cookies.remove("Login")
      localStorage.removeItem("Authorization")
      this.logDenLu = false
      this.$router.push({path:"/home",query:{id:this.nameList[0].deptId}})

    },
    parentMethod(data) {
      // 在这里处理子组件传递过来的数据，可以调用父组件的其他方法
     this.dengLu = data
    },
    parentMethod2(data){
      this.logDenLu = data
    },
    //导航栏
    getHomeList(){
      //获取首页导航栏信息
      this.homeList = []
      getAPIList(0).then(data =>{
        this.nameList = data.data
        console.log(data.data)
        for (let i = 0; i < this.nameList.length; i++) {
          let azx =  {id:null,name:'',to:'/home',linkAddress:null,openWith:0}
          azx.id =  this.nameList[i].deptId
          azx.name =  this.nameList[i].name
          azx.linkAddress = this.nameList[i].linkAddress
          azx.openWith = this.nameList[i].openWith
          this.homeList.push(azx)
          
        }
        console.log("aaa:",this.homeList.length );
        if (this.homeList.length > 0) {
    const firstMenuItem = this.homeList[0];
    console.log("aaa:",this.homeList[0] );

  
        if ( this.$route.query.id === undefined){
          this.$router.push({path:"/home",query:{id:this.nameList[0].deptId}})
        } 
  }

    /*     let azx =  {id:null,name:'数据API',to:'/API',linkAddress:null}
        this.homeList.splice(1,0,azx)
        if ( this.$route.query.id === undefined){
          this.$router.push({path:"/home",query:{id:this.nameList[0].deptId}})
        } */
      })


       
    },

  },

};
</script>

<style>
.imgDiv img {
  background-color: transparent;
}
.a{
  cursor: pointer;
}
.inputDiv>>>.el-button{
  border: 0;
}
.div001{
  width: 1440px;
  height: 700px;
  position: relative;

}
.divTop{
   width: 100%;
   height: 60px;
   position: relative;
   z-index: 2;
   

 }


.liSpan{
  display: inline-block;
  color: #000;
margin-left: 10px;

}

.imgDiv{
  background-color: transparent;
  position: absolute;
  top: 10px;
  left: 100px;
  width: 150px;
  height: 41px;
}
.inputDiv{
  border-radius: 30px;
  position: absolute;
  top: 11px;
  right: 100px;
}
.inputDiv span{
  color: #FFFFFF;
  margin-left: 3px;
  font-size: 13px;
}
a{
  color: #ffffff;
  text-decoration: none;
}
img{
  width: 100%; height: 100%; object-fit: cover;
}

</style>

/**
 * 
 * @returns 获取到屏幕宽高
 */
export function getwh() {  
    function getw(){
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
     }
     function geth(){
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
     }
    return {
      geth,//获取高度
      getw//获取宽度
    }
  }

/**
 * 
 * @returns this.$refs.myCanvas;  = canvas
 */
  export function getCanvas2D(canvas){
    const context = canvas.getContext('2d');
    function setColor(color){
      // 设置线条颜色
      context.strokeStyle = color
    }
    //起始位置
    function setMoveTo(x,y){
        // 设置线条颜色
        context.moveTo(x, y);
      }
       //目标位置
    function setLineTo(x,y){
        context.lineTo(x, y);
      }
      function go(x,y){
         // 绘制线条
    context.stroke();
      }
      return{
        setColor,
        setMoveTo,
        setLineTo,
        go
      }
  }

  

  
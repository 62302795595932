<template>
  <div >
  <div class="popup-overlay">
    <div class="popup-content">
      <span style="display: inline-block; position: relative; left: 420px; top: 20px" @click="aaa" class="a">X</span>
      <div style="width: 50%; height: 60px; position: relative; top: 40px; left: 111px">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">短信登录/注册</el-menu-item>
<!--          <el-menu-item index="2">密码登录</el-menu-item>-->
        </el-menu>
      </div>

      <div v-if="activeIndex == '1'" style="position: relative; width: 60%; top: 76px; left: 90px">

        <el-input v-model="inputName" @input="input"  placeholder="请输入电话号"></el-input>
        <span style=" top: 38px;" class="spanYanZheng">{{inputNameY}}</span>
        <el-input style="margin-top: 20px" @input="input2" placeholder="请输入6位验证码" v-model="inputPassword"></el-input>
       <span  style=" top: 100px;" class="spanYanZheng">{{inputPasswordY}}</span>
        <a class="a" style="color: #004d8c; font-size: 12px; position: absolute; top: 72px; right: 10px;" @click="getUUID">{{startCountdownData}}</a>
        <el-button style="position: relative; top: 40px; width: 100%; background-color: rgba(2, 52, 133, 1);" @click="validateAndSubmit">登录/注册</el-button>
      </div>

<!--      <div v-if="activeIndex == '2'" style="position: relative; width: 60%; top: 76px; left: 90px">-->
<!--        <el-input v-model="Name" placeholder="请输入账号"></el-input>-->
<!--        <span style=" top: 38px;" class="spanYanZheng">{{NameY}}</span>-->
<!--        <el-input style="margin-top: 20px" placeholder="请输入密码" v-model="Password"></el-input>-->
<!--        <span style=" top: 94px;" class="spanYanZheng">{{PasswordY}}</span>-->
<!--        <el-button style="position: relative; top: 40px; width: 100%; background-color: rgba(2, 52, 133, 1);" @click="validateAndSubmit">登录/注册</el-button>-->
<!--      </div>-->

      <div style="position: relative; top: 120px; font-size: 12px; text-align: center">
        <p>未注册的手机号验证后将自动登录</p>
        <input type="checkbox" id="gender" name="gender" value="male" >
        我以阅读并同意<a style="color: #004d8c" class="a">《用户协议》</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import App from "@/App"
import Cookies from 'js-cookie'
import {getAxios, postAxios} from "@/API/getAxios";
import axios from "axios";

export default {
  name: '',
  components: {App},

  data() {
    return {
      startCountdownData:'获取验证码',
      seconds:60, //一分钟失效
      isMethodEnabled:true,
      inputName: '',//手机号
      inputPassword: '',//验证码
      // Name: '',
      // Password: '',
      activeIndex: '1',

      inputNameY: '',//手机验证提醒
      inputPasswordY: '',//验证码提醒
      // NameY: '',
      // PasswordY: '',
      UUIDData:{uuid:''},//
      // data:{
      //   "code": null,
      //   "phone": null,
      //   "uuid": null
      // },

      //关闭定时器
      countdownInterval:null,

    }
  },
  mounted() {
    // Cookies.remove("userLogin")
    // this.getUser()

  },
  methods: {
    //存Cookies
    setUser() {
      //存
      Cookies.set("userLogin",this.inputName,{expires: 7 })
      Cookies.remove("Login")
      Cookies.set("Login",true,{expires: 7 })

    },
    aaa(){
      this.$emit('custom-event', false);
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
    },
    // 获取验证码
    getUUID(){
      if (!/^1[34578]\d{9}$/.test(this.inputName)) {
        this.inputNameY = "请输入有效的手机号"
        return
      }
      this.startCountdown()
    },
    //注册方法
    postUsername(){
      let data = {code: '',phone:'',uuid:''}
        data.code = this.inputPassword.toString()
        data.phone = this.inputName.toString()
        data.uuid = this.UUIDData.uuid.toString()
        localStorage.setItem('uuid',data.uuid)
        axios.post('/api/shopUserRegister',data).then(data =>{
          var zxc = data.data
         if (zxc.code == 200){
           localStorage.setItem('Authorization',zxc.token.toString())
           this.$emit('custom-event2', true);
           this.$emit('custom-event', false);
           this.setUser()
           // 登录成功！！！
           this.$message({
             message: '恭喜你，登录成功',
             type: 'success'
           });
         }else {
           this.startCountdownData = '重新获取验证码'
           clearInterval(this.countdownInterval);
           this.isMethodEnabled = true
           this.$message({
             message: '验证码错误，请重新获取',
             type: 'warning'
           });
         }
      })

    },
    //验证码倒计时
    startCountdown() {
     if (this.isMethodEnabled == true){
       let data = {phone:this.inputName}
       axios.get('/api/captchaPhoneCode',{params:data}).then(data =>{
         this.UUIDData = data.data
       })
         this.seconds = 60
         this.seconds--;
         this.startCountdownData = this.seconds
         this.countdownInterval = setInterval(() => {
         if (this.seconds > 0) {
           this.seconds--;
           this.startCountdownData = this.seconds
           this.isMethodEnabled = false
         } else {
           clearInterval(this.countdownInterval);
           this.isMethodEnabled = true
           this.startCountdownData = '重新获取验证码'
         }
       }, 1000);
     }
    },

    input(){
      if (!/^1[34578]\d{9}$/.test(this.inputName)) {
        this.inputNameY = "请输入有效的手机号"
        return
      }
      this.inputNameY = ""
    },
    input2(){
      if (!/^\d{4}$/.test(this.inputPassword)) {
        this.inputPasswordY = "请输入有效的六位验证码"
        return
      }
      this.inputPasswordY = ''
    },
    validateAndSubmit() {
      var gender =  document.getElementById("gender")
      var gen =  gender.checked
      if (gen != true){
        this.$message({
          message: '请先勾选同意用户协议',
          type: 'warning'
        });
        return;
      }
      if (this.activeIndex === '1') {

        // 执行登录/注册逻辑
        this.postUsername()


      }
      // else if (this.activeIndex === '2') {
      //   if (!this.Name) {
      //     this.NameY = "账号不能为空"
      //     return
      //   }
      //   this.NameY = ""
      //   if (!this.Password) {
      //     this.PasswordY = "密码不能为空"
      //
      //     return
      //   }
      //   this.NameY = ''
      //   this.PasswordY = ''
      //   // 执行登录/注册逻辑
      //
      // }
    }
  }
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明的遮罩层 */
  z-index: 2;
}

.popup-content {
  background-color: white;
  width: 450px;
  height: 450px;
  /* 根据需要设置弹窗的宽度、最大宽度等样式 */
  z-index: 0;
}
.spanYanZheng{
  display: inline-block;
  position: absolute;
  width: 100px;
  font-size: 10px;
  color: red;
  left: 0px;
}
</style>

//引入vue-router
import VueRouter from 'vue-router'
//引入vue
import Vue from 'vue'
//挂载路由插件
Vue.use(VueRouter)



//定义路由规则：url和组件一一对应
var routes = [
     //配置一条路由（规则）
       {
        path: '/home',
        component: () => import('@/components/set/home/home'),

        },
        {
          path: '/centre',
          component: () => import('@/components/set/centre/centre'),
          hidden: true,

        },
        {
          path: '/API',
          component: () => import('@/components/set/API/zhuYei'),
          hidden: true,
        },
        {
          path: '/use',
          component: () => import('@/components/set/home/home'),
          hidden: true,
            meta: {
            }
        },
        {
          path: '/username',
          component: () => import('@/components/set/user/user'),
          hidden: true,
        },
        {
        path: '/detail',
        component: () => import('@/components/set/API/detail'),
        hidden: true,
        },
    {
        path: '/price',
        component: () => import('@/components/set/price/price'),
        hidden: true,
    },
    {
        path: '/trade',
        component: () => import('@/components/set/trade/trade'),
        hidden: true,
    },

    // {
    //     path: '/',
    //     component: () => import('@/components/set/home/home'),
    //     hidden: true,
    //     meta: {
    //         noCache: true // 禁用缓存
    //     }
    // },


    ]





//生成路由实例
const router = new VueRouter({
     routes //配置路由规则
    })

    export default router //对外暴露路由实例

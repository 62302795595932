import Vue from 'vue'
import App from './App.vue'

import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from "axios";
import {getAxios} from "@/API/getAxios";
axios.interceptors.request.use(config =>{
    // 获取存储在localStorage中的Authorization值
    const authorization = localStorage.getItem('Authorization');
    // 添加Authorization到Headers中
    if (authorization) {
        config.headers['Authorization'] = 'Bearer ' + authorization
    }
    return config
})
Vue.prototype.$http = axios

router.beforeEach((to, from, next) => {
    let title = ''
    let description = ''
    title = localStorage.getItem('title')
    description =  localStorage.getItem('description')
     if (title !== null && description !== null){
         document.title = title + (description ? ' - ' + description : ''); // 修改浏览器标签页的标题
         next();
     }else {
         getAxios('/Websitelist').then(data => {
             title = data.rows[0].title
             description =   data.rows[0].description
             localStorage.setItem('title', title)
             localStorage.setItem('description', description)
             document.title = title + (description ? ' - ' + description : ''); // 修改浏览器标签页的标题
             next();
         })
     }






});


Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    data(){
        return{
            index:0,
            daoHangList:{}
        }
    },
  render: h => h(App),
   router
}).$mount('#app')


